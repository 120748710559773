import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { NotificationRoutingModule } from './notification-routing.module';
import { CaSharedModule } from 'src/ca-shared/ca-shared.module';
import { SharedModule } from '../shared/shared.module';
import { NotificationMainComponent } from './notification-main/notification-main.component';
import { NotificationListToolbarComponent } from './notification-list-toolbar/notification-list-toolbar.component';
import { AngularSplitModule } from 'angular-split';
import { MatTableModule } from '@angular/material/table';
import { CoreModule } from 'src/core/core.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DummyNotificationComponent } from './types/dummy-notification/dummy-notification.component';
import { NotificationRowComponent } from './types/notification-row/notification-row.component';
import { PlaygroundNotificationComponent } from './types/playground-notification/playground-notification.component';
import { RealTimeEventDetectedNotificationComponent } from '../real-time/notifications/real-time-event-detected-notification/real-time-event-detected-notification.component';
import { ConversationListExportReadyNotificationComponent } from '../conversation/notifications/conversation-list-export-ready-notification/conversation-list-export-ready-notification.component';
import { EvaluationResultExportReadyNotificationComponent } from '../quality/notifications/evaluation-result-export-ready-notification/evaluation-result-export-ready-notification.component';
import { TopicTrainingFailedNotificationComponent } from '../administration/settings/notifications/topic/topic-training-failed-notification/topic-training-failed-notification.component';
import { TopicTrainingReadyNotificationComponent } from '../administration/settings/notifications/topic/topic-training-ready-notification/topic-training-ready-notification.component';
import { TopicTrainingStartedNotificationComponent } from '../administration/settings/notifications/topic/topic-training-started-notification/topic-training-started-notification.component';
import { BatchTaggingCompletedNotificationComponent } from '../conversation/notifications/batch-tagging-completed-notification/batch-tagging-completed-notification.component';
import { BatchTaggingFailedNotificationComponent } from '../conversation/notifications/batch-tagging-failed-notification/batch-tagging-failed-notification.component';
import { BatchTaggingStartedNotificationComponent } from '../conversation/notifications/batch-tagging-started-notification/batch-tagging-started-notification.component';
import { ConversationDeleteCompletedNotificationComponent } from '../conversation/notifications/conversation-delete-completed-notification/conversation-delete-completed-notification.component';
import { ConversationListExportFailedNotificationComponent } from '../conversation/notifications/conversation-list-export-failed-notification/conversation-list-export-failed-notification.component';
import { AssignmentDueDateApproachingNotificationComponent } from '../quality/notifications/assignment-due-date-approaching-notification/assignment-due-date-approaching-notification.component';
import { ConversationCommentNotificationComponent } from '../quality/notifications/conversation-comment-notification/conversation-comment-notification.component';
import { ConversationEvaluatedNotificationComponent } from '../quality/notifications/conversation-evaluated-notification/conversation-evaluated-notification.component';
import { EvaluationObjectionAgentNotificationComponent } from '../quality/notifications/evaluation-objection-agent-notification/evaluation-objection-agent-notification.component';
import { EvaluationObjectionEvaluatorNotificationComponent } from '../quality/notifications/evaluation-objection-evaluator-notification/evaluation-objection-evaluator-notification.component';
import { EvaluationResultExportFailedNotificationComponent } from '../quality/notifications/evaluation-result-export-failed-notification/evaluation-result-export-failed-notification.component';
import { EvaluationResultAgentNotificationComponent } from '../quality/notifications/evaluation-result-agent-notification/evaluation-result-agent-notification.component';
import { EvaluationResultManagerNotificationComponent } from '../quality/notifications/evaluation-result-manager-notification/evaluation-result-manager-notification.component';
import { EvaluationResultAutomaticManagerNotificationComponent } from '../quality/notifications/evaluation-result-automatic-manager-notification/evaluation-result-automatic-manager-notification.component';
import { QueryIndexAbortedNotificationComponent } from '../query/notifications/query-index-aborted-notification/query-index-aborted-notification.component';
import { QueryIndexCompletedNotificationComponent } from '../query/notifications/query-index-completed-notification/query-index-completed-notification.component';
import { AgentCategoryExportReadyNotificationComponent } from '../quality/notifications/agent-category-export-ready-notification/agent-category-export-ready-notification.component';
import { GenAiProjectReadyNotificationComponent } from '../analysis/notifications/gen-ai-project-ready-notification/gen-ai-project-ready-notification.component';
import { GenAiProjectFailedNotificationComponent } from '../analysis/notifications/gen-ai-project-failed-notification/gen-ai-project-failed-notification.component';
import { GenAiProjectDeletionCompletedNotificationComponent } from '../analysis/notifications/gen-ai-project-deletion-completed-notification/gen-ai-project-deletion-completed-notification.component';
import { NonFcrExportReadyNotificationComponent } from '../analysis/notifications/non-fcr-export-ready-notification/non-fcr-export-ready-notification/non-fcr-export-ready-notification.component';
import { NonFcrExportFailedNotificationComponent } from '../analysis/notifications/non-fcr-export-failed-notification/non-fcr-export-failed-notification/non-fcr-export-failed-notification.component';
import { AgentObjectionExportFailedNotificationComponent } from '../quality/quality-report/notifications/agent-objection-export-failed-notification/agent-objection-export-failed-notification.component';
import { AgentObjectionExportReadyNotificationComponent } from '../quality/quality-report/notifications/agent-objection-export-ready-notification/agent-objection-export-ready-notification.component';
import { AgentPerformanceExportFailedNotificationComponent } from '../quality/quality-report/notifications/agent-performance-export-failed-notification/agent-performance-export-failed-notification.component';
import { AgentPerformanceExportReadyNotificationComponent } from '../quality/quality-report/notifications/agent-performance-export-ready-notification/agent-performance-export-ready-notification.component';
import { CategoryDeacticatedNotificationComponent } from '../category/notifications/category-deacticated-notification/category-deacticated-notification.component';
import { EvaluatorPerformanceExportReadyNotificationComponent } from '../quality/quality-report/notifications/evaluator-performance-export-ready-notification/evaluator-performance-export-ready-notification.component';
import { EvaluatorPerformanceExportFailedNotificationComponent } from '../quality/quality-report/notifications/evaluator-performance-export-failed-notification/evaluator-performance-export-failed-notification.component';
import { CategoryListExportFailedNotificationComponent } from '../category/notifications/category-list-export-failed-notification/category-list-export-failed-notification.component';
import { CategoryListExportReadyNotificationComponent } from '../category/notifications/category-list-export-ready-notification/category-list-export-ready-notification.component';
import { AnalysisAgentPerformanceExportReadyNotificationComponent } from '../quality/notifications/analysis-agent-performance-export-ready-notification/analysis-agent-performance-export-ready-notification.component';
import { IdleUsersCountNotificationComponent } from '../replacement/user/notifications/idle-users-count-notification/idle-users-count-notification.component';
import { AutomaticSummarizationErrorNotificationComponent } from '../new-conversation/notifications/automatic-summarization-error-notification/automatic-summarization-error-notification.component';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationMainComponent,
    NotificationListToolbarComponent,
    DummyNotificationComponent,
    NotificationRowComponent,
    PlaygroundNotificationComponent,
  ],
  imports: [
    CaSharedModule,
    CommonModule,
    SharedModule,
    CoreModule,
    NotificationRoutingModule,
    AngularSplitModule,
    MatTableModule,
    MatPaginatorModule,
    NgbModule,
  ],
  providers: [
    ConversationDeleteCompletedNotificationComponent,
    ConversationListExportFailedNotificationComponent,
    ConversationListExportReadyNotificationComponent,
    TopicTrainingFailedNotificationComponent,
    TopicTrainingReadyNotificationComponent,
    TopicTrainingStartedNotificationComponent,
    BatchTaggingCompletedNotificationComponent,
    BatchTaggingFailedNotificationComponent,
    BatchTaggingStartedNotificationComponent,
    AssignmentDueDateApproachingNotificationComponent,
    ConversationCommentNotificationComponent,
    ConversationEvaluatedNotificationComponent,
    EvaluationObjectionAgentNotificationComponent,
    EvaluationObjectionEvaluatorNotificationComponent,
    EvaluationResultExportFailedNotificationComponent,
    EvaluationResultExportReadyNotificationComponent,
    EvaluationResultAgentNotificationComponent,
    EvaluationResultManagerNotificationComponent,
    EvaluationResultAutomaticManagerNotificationComponent,
    QueryIndexAbortedNotificationComponent,
    QueryIndexCompletedNotificationComponent,
    RealTimeEventDetectedNotificationComponent,
    AgentCategoryExportReadyNotificationComponent,
    NonFcrExportReadyNotificationComponent,
    NonFcrExportFailedNotificationComponent,
    GenAiProjectReadyNotificationComponent,
    GenAiProjectFailedNotificationComponent,
    GenAiProjectDeletionCompletedNotificationComponent,
    AgentObjectionExportFailedNotificationComponent,
    AgentObjectionExportReadyNotificationComponent,
    AgentPerformanceExportFailedNotificationComponent,
    AgentPerformanceExportReadyNotificationComponent,
    CategoryDeacticatedNotificationComponent,
    EvaluatorPerformanceExportReadyNotificationComponent,
    EvaluatorPerformanceExportFailedNotificationComponent,
    CategoryListExportFailedNotificationComponent,
    CategoryListExportReadyNotificationComponent,
    AnalysisAgentPerformanceExportReadyNotificationComponent,
    IdleUsersCountNotificationComponent,
    AutomaticSummarizationErrorNotificationComponent,
  ],
  bootstrap: [DummyNotificationComponent, PlaygroundNotificationComponent],
})
export class NotificationModule {}

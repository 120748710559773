import { ConfigStateService, LocalizationService, PermissionService } from '@abp/ng.core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@abp/ng.theme.shared';
import { ConversationService } from 'src/core/services/conversation/conversation.service';
import { ConversationListType } from './../../../core/models/generic-lookup-type/conversation/conversation-list-type.glt';
import { Store } from '@ngxs/store';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import {
  CategorySelectionConfigurationModel,
  DateSelectionConfigurationModel,
  DepartmentSelectionConfigurationModel,
  DepartmentSelectionType,
  FilterPanelComponent,
  FilterPanelConfigurationModel,
  GenericLookupSelectionConfigurationModel,
  GenericLookupSelectionType,
  QuerySelectionConfigurationModel,
  TextEditorConfigurationModel,
  UserSelectionConfigurationModel,
  UserSelectionType,
} from 'src/ca-shared/filter-panel/filter-panel.module';
import { Editor } from 'src/ca-shared/filter-panel/models/editor.enum';
import { ConversationType } from 'src/core/models/generic-lookup-type/conversation/conversation-type.glt';
import { ConversationEvaluation } from 'src/core/models/generic-lookup-type/conversation/conversation-evaluation.glt';
import { CallAnalysisStatus } from 'src/core/models/generic-lookup-type/call/call-analysis-status.glt';
import { ConversationListeningStatus } from 'src/core/models/generic-lookup-type/conversation/conversation-listening-status.glt';
import {
  ActiveListChange,
  FilterChange,
  SavePage,
} from 'src/core/actions/conversation/conversation-module.actions';
import { GlobalSettingsService } from 'src/core/services/settings/global-settings.service';
import { CADatePipe } from 'src/core/pipes/ca-date.pipe';
import { CallDirection } from 'src/core/models/generic-lookup-type/call/call-direction.glt';
import { DateDisplayType } from 'src/ca-shared/conversation-date-filter/models/date-display-type.enum';
import { DepartmentFilterModeOption } from 'src/core/models/generic-lookup-type/department/department-filter-mode-option.glt';
import { DepartmentSelectorInputModel } from 'src/ca-shared/department-selector/models/department-selector-input.model';
import { AgentListFilterModeOption } from 'src/core/models/generic-lookup-type/identity/agent-list-filter-mode-option.glt';
import { FeatureService } from 'src/core/services/feature/feature.service';
import { FeatureConstants } from 'src/core/constants/feature-constant';
import { FilterButtonType } from 'src/core/models/enum/filter-button-type.enum';
import { QueryDto } from 'src/core/models/query/query.dto';
import { TopicSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/topic-selection/models/topic-selection-configuration.model';
import { TagSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/tag-selection/models/tag-selection-configuration.model';
import { CallReleasingParty } from 'src/core/models/generic-lookup-type/call/call-releasing-party.glt';
import { AttachedDataSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/attached-data-selection/models/attached-data-selection-configuration.model';
import { NumberEditorConfigurationModel } from 'src/ca-shared/filter-panel/children/number-editor/models/number-editor-configuration.model';
import { ConversationSide } from 'src/core/models/generic-lookup-type/conversation/conversation-side.glt';
import { ConversationEvaluationMark } from 'src/core/models/generic-lookup-type/conversation/conversation-evaluation-mark.glt';
import { Operators } from 'src/core/models/request/operator.enum';
import { BooleanSelectionConfigurationModel } from 'src/ca-shared/filter-panel/children/boolean-selection/models/boolean-selection-configuration.model';
import { NumberEditorWithOperatorConfigurationModel } from 'src/ca-shared/filter-panel/children/number-editor-with-operator/models/number-editor-with-operator-configuration.model';
import { CategoryDto } from 'src/core/models/query/category.dto';
import { CategoryType } from 'src/core/models/enum/category-type.enum';
import { CategoryMatchType } from 'src/core/models/generic-lookup-type/category/category-match-type.glt';

@Component({
  selector: 'ca-conversation-list-filter-panel',
  templateUrl: './conversation-list-filter-panel.component.html',
  styleUrls: ['./conversation-list-filter-panel.component.scss'],
})
export class ConversationListFilterPanelComponent implements OnInit {
  queryParamFilters: any;
  filterPanelConfig: FilterPanelConfigurationModel;

  @ViewChild(FilterPanelComponent, { read: FilterPanelComponent })
  filterPanel: FilterPanelComponent;

  @Output()
  collapsed: EventEmitter<{
    width: number;
  }> = new EventEmitter();

  @Output()
  expanded = new EventEmitter();
  qualityManagementFeatureEnabled: boolean;
  canSeeEvaluationFilter: boolean;
  private readonly settingNameForMaxIntegerValue = 'Number.IntMaxValue';

  constructor(
    private datePipe: CADatePipe,
    private store: Store,
    private conversationService: ConversationService,
    private toastr: ToasterService,
    private router: Router,
    private localizationService: LocalizationService,
    private featureService: FeatureService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private globalSettingsService: GlobalSettingsService,
    private operators: Operators,
    private config: ConfigStateService
  ) {
    let disableState = false;
    const me = this;
    const routeSnapshot = this.route.snapshot;
    const allExceptText = this.localizationService.instant('::AllExcept');
    const analyticsEnabled = this.featureService.isEnabled(FeatureConstants.Analytics);
    const defaultOperator = this.operators.GreaterThanOrEquals;

    this.qualityManagementFeatureEnabled = this.featureService.isEnabled(
      FeatureConstants.QualityManagement
    );
    if (routeSnapshot.queryParams.filters != null) {
      disableState = true;
      this.queryParamFilters = JSON.parse(routeSnapshot.queryParams.filters);
    }

    const quickSearchTermConfiguration: TextEditorConfigurationModel = {
      key: 'quickSearchTerm',
      editor: Editor.QuickSearchWithConversationSide,
      defaultValue: { quickSearchText: '', sideId: ConversationSide.any },
      placeholder: '::EnterIdExternalIdOrPhrase',
      iconCls: 'fas fa-search search-icon',
      clearBottomMargin: true,
      rememberMe(values: any): boolean {
        const quickSearchText = values?.quickSearchTerm.quickSearchText;
        const id = Number(quickSearchText);

        if (!isNaN(id) && id > 0) {
          // if an id is set to input then not remember value
          return false;
        }

        return true;
      },
    };

    let excludedConversationListTypes = [
      ...[ConversationListType.mixed],
      ...this.conversationService.getExcludedConversationListTypes(),
    ];

    const conversationListTypeSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'conversationListType',
      editor: Editor.GenericLookup,
      type: GenericLookupSelectionType.Checkbox,
      genericLookupType: ConversationListType,
      valuesToExclude: excludedConversationListTypes,
      emitChangeEvent: true,
      defaultValue: [],
      onChange(filterPanel: FilterPanelComponent, value: number[]) {
        const callPanelVisible = value.indexOf(ConversationListType.call) > -1 || value.length <= 0;
        const chatPanelVisible = value.indexOf(ConversationListType.chat) > -1 || value.length <= 0;
        filterPanel.changePanelVisibility('call', callPanelVisible);
        filterPanel.changePanelVisibility('chat', chatPanelVisible);

        const conversationListTypes = [];

        if (value.indexOf(ConversationListType.call) > -1) {
          conversationListTypes.push(ConversationType.call);
        }

        if (value.indexOf(ConversationListType.chat) > -1) {
          conversationListTypes.push(ConversationType.chat);
        }

        if (value.indexOf(ConversationListType.meeting) > -1) {
          conversationListTypes.push(ConversationType.meeting);
        }

        if (value.indexOf(ConversationListType.videoCall) > -1) {
          conversationListTypes.push(ConversationType.videoCall);
        }

        if (value.indexOf(ConversationListType.email) > -1) {
          conversationListTypes.push(ConversationType.email);
        }

        filterPanel
          .getEditor('category', 'category')
          .setExtraData('conversationType', conversationListTypes);
        filterPanel.getEditor('category', 'category').reload();
      },
    };

    const categorySelectionConfiguration: CategorySelectionConfigurationModel = {
      key: 'category',
      editor: Editor.Category,
      defaultValue: this.getQuerySelectionDefault(),
      autoLoad: true,
      includeUncategorized: true,
    };

    const tagSelectionConfiguration: TagSelectionConfigurationModel = {
      key: 'tag',
      editor: Editor.Tag,
      defaultValue: null,
      autoLoad: false,
    };

    const attachedDataSelectionConfiguration: AttachedDataSelectionConfigurationModel = {
      key: 'attachedData',
      editor: Editor.AttachedData,
      label: '::AttachedData',
      defaultValue: -1,
      autoLoad: true,
    };

    const dateSelectionConfiguration: DateSelectionConfigurationModel = {
      key: 'date',
      editor: Editor.Date,
      preventReset: true,
      minDate: globalSettingsService.systemStartDate,
      maxDate: globalSettingsService.systemEndDate,
      defaultValue: this.getDateSelectionDefault(),
    };

    const departmentSelectionConfiguration: DepartmentSelectionConfigurationModel = {
      key: 'department',
      editor: Editor.Department,
      defaultValue: {
        selectedDepartments: [],
        filterModeId: DepartmentFilterModeOption.SelectedDepartments,
      },
      selectionType: DepartmentSelectionType.Multiple,
      filterByStatus: true,
      onChange(filterPanel: FilterPanelComponent, value: DepartmentSelectorInputModel) {
        let departments = value.selectedDepartments;
        if (departments && departments.length > 0) {
          const ids = [];

          for (let i = 0; i < departments.length; i++) {
            ids.push(departments[i].id);
          }
          filterPanel
            .getEditor('user', 'user')
            .setExtraData('departmentFilterModeId', value.filterModeId);
          filterPanel.getEditor('user', 'user').setExtraData('departmentIds', ids);
          filterPanel.getEditor('user', 'user').reload();
        } else {
          filterPanel.getEditor('user', 'user').setExtraData('departmentIds', null);
          filterPanel.getEditor('user', 'user').reload();
        }
      },
    };

    const userSelectionConfiguration: UserSelectionConfigurationModel = {
      key: 'user',
      editor: Editor.User,
      defaultValue: {
        selectedUsers: [],
        filterModeId: AgentListFilterModeOption.SelectedAgents,
      },
      selectionType: UserSelectionType.Multiple,
      onlyAgents: true,
      autoLoad: false,
      filterByStatus: true,
    };

    const callAnalysisStatusSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'callAnalysisWithOr',
      editor: Editor.GenericLookup,
      hidden: !analyticsEnabled,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: CallAnalysisStatus,
      label: 'Conversation::AnalysisStatus',
      defaultValue: -1,
      conversationInline: true,
    };

    const callListeningStatusSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'listeningStatusWithOr',
      editor: Editor.GenericLookup,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: ConversationListeningStatus,
      label: 'Conversation::ListeningStatus',
      defaultValue: -1,
      conversationInline: true,
    };

    const categoryMatchingTypeConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'categoryMatchType',
      editor: Editor.GenericLookup,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: CategoryMatchType,
      defaultValue: CategoryMatchType.matching,
      showAllOption: false,
    };

    const callDirectionSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'directions',
      editor: Editor.GenericLookup,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: CallDirection,
      label: 'Conversation::Direction',
      defaultValue: [],
      conversationInline: true,
      multiple: true,
      showAllOption: false,
    };

    const callReleasingPartySelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'releasingPartyWithOr',
      editor: Editor.GenericLookup,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: CallReleasingParty,
      label: 'Conversation::ReleasingPartyFilter',
      defaultValue: -1,
      conversationInline: true,
    };

    const callerNumberConfiguration: TextEditorConfigurationModel = {
      key: 'callerNumberWithOr',
      editor: Editor.Text,
      label: 'Conversation::CallerNumber',
      defaultValue: '',
      callInline: true,
    };

    const calledNumberConfiguration: TextEditorConfigurationModel = {
      key: 'calledNumberWithOr',
      editor: Editor.Text,
      label: 'Conversation::CalledNumber',
      defaultValue: '',
      callInline: true,
    };

    const relatedGroupIdConfiguration: TextEditorConfigurationModel = {
      key: 'relatedGroupIdWithOr',
      editor: Editor.Text,
      label: 'Conversation::RelatedGroupIdFilter',
      defaultValue: '',
      callInline: true,
    };

    const chatTitleConfiguration: TextEditorConfigurationModel = {
      key: 'titleWithOr',
      editor: Editor.Text,
      label: 'CA::Title',
      defaultValue: '',
      chatInline: true,
    };

    const chatParticipantConfiguration: TextEditorConfigurationModel = {
      key: 'participantNameWithOr',
      editor: Editor.Text,
      label: 'Conversation::ParticipantName',
      defaultValue: '',
      chatInline: true,
    };

    const evaluationStatusSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'evaluationStatus',
      editor: Editor.GenericLookup,
      hidden: !this.qualityManagementFeatureEnabled,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: ConversationEvaluation,
      label: 'Conversation::EvaluationStatus',
      defaultValue: -1,
      evaluationInline: true,
    };

    const evaluationMarkSelectionConfiguration: GenericLookupSelectionConfigurationModel = {
      key: 'evaluationMark',
      editor: Editor.GenericLookup,
      hidden: !this.qualityManagementFeatureEnabled,
      type: GenericLookupSelectionType.Combobox,
      genericLookupType: ConversationEvaluationMark,
      label: 'Conversation::EvaluationMark',
      defaultValue: -1,
      evaluationInline: true,
    };

    const minHoldDurationConfiguration: NumberEditorConfigurationModel = {
      key: 'minHoldDurationWithOr',
      editor: Editor.Number,
      label: 'Conversation::MinHoldDuration',
      defaultValue: '',
      callInline: true,
      minValue: 0,
      maxValue: Number(this.config.getSetting(this.settingNameForMaxIntegerValue)),
      showValidationMessage: false,
    };

    const pbxAgentIdConfiguration: TextEditorConfigurationModel = {
      key: 'pbxAgentIdWithOr',
      editor: Editor.Text,
      label: 'User::PbxAgentId',
      defaultValue: '',
      callInline: true,
    };

    const durationConfiguration: NumberEditorWithOperatorConfigurationModel = {
      key: 'duration',
      editor: Editor.NumberWithOperator,
      label: 'Conversation::DurationWithUnit',
      defaultValue: {
        editorValue: 0,
        operator: defaultOperator,
      },
      minValue: 0,
      maxValue: Number(this.config.getSetting(this.settingNameForMaxIntegerValue)),
      defaultOperator: defaultOperator,
      operators: [this.operators.GreaterThanOrEquals, this.operators.LessThanOrEquals],
      showValidationMessage: false,
    };

    const screenRecordStatusSelectionConfiguration: BooleanSelectionConfigurationModel = {
      key: 'screenRecordStatus',
      editor: Editor.BooleanSelection,
      label: 'Conversation::ScreenRecord',
      showAllOption: true,
      defaultValue: '-1',
      trueOptionText: '::Exist',
      falseOptionText: '::NotExist',
    };

    const ratingForNPSConfiguration: NumberEditorWithOperatorConfigurationModel = {
      key: 'ratingForNPS',
      editor: Editor.NumberWithOperator,
      label: 'CA::RatingForNPS',
      defaultValue: {
        editorValue: 0,
        operator: defaultOperator,
      },
      minValue: 0,
      maxValue: 10,
      defaultOperator: defaultOperator,
      operators: [this.operators.GreaterThanOrEquals, this.operators.LessThanOrEquals],
      showValidationMessage: false,
    };

    this.filterPanelConfig = {
      key: 'conversationList',
      collapsible: true,
      hideHeader: false,
      closeOthers: true,
      disableRestoreFromState: disableState,
      buttons: {
        expandAll: false,
        clear: true,
        filter: true,
        collapseAll: false,
      },
      items: [
        {
          title: '::QuickSearch',
          key: 'quickSearchTerm',
          items: [quickSearchTermConfiguration],
          ignoreKeyOnValueCollection: true,
          hideHeader: true,
          sticky: true,
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return values.quickSearchTerm?.quickSearchText &&
              values.quickSearchTerm?.quickSearchText.trim().length > 0
              ? {
                  visible: true,
                  text: values.quickSearchTerm.quickSearchText,
                  count: 1,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: '::ConversationType',
          key: 'conversationListType',
          items: [conversationListTypeSelectionConfiguration],
          ignoreKeyOnValueCollection: true,
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return {
              visible: values.conversationListType?.length > 0,
              text: values.conversationListType?.length,
              count: values.conversationListType?.length,
            };
          },
        },
        {
          title: 'Query::Date',
          key: 'date',
          ignoreKeyOnValueCollection: true,
          items: [dateSelectionConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let result = '';

            if (values.date) {
              const strStart = me.datePipe.transform(
                values.date.start,
                DateDisplayType.DateTimeWithoutSeconds,
                true
              );
              const strEnd = me.datePipe.transform(
                values.date.end,
                DateDisplayType.DateTimeWithoutSeconds,
                true
              );

              result = [strStart, strEnd].join(' - ');
            }

            return values.date
              ? { visible: true, text: result, count: 1 }
              : { visible: false, text: '', count: 0 };
          },
        },
        {
          title: 'Category::Category',
          key: 'category',
          items: [categoryMatchingTypeConfiguration, categorySelectionConfiguration],
          ignoreKeyOnValueCollection: false,
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            return values?.category?.category && values.category.category.length > 0
              ? {
                  visible: true,
                  text:
                    values.category.category[0].categoryType !== CategoryType.Uncategorized
                      ? values.category.categoryMatchType == CategoryMatchType.notMatching
                        ? me.localizationService.instant(
                            'Category::CategoryMatchType.NotMatching'
                          ) +
                          ' "' +
                          values.category.category[0].name +
                          '"'
                        : values.category.category[0].name
                      : me.localizationService.instant('Category::Uncategorized'),
                  count: 1,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: '::Department',
          key: 'department',
          ignoreKeyOnValueCollection: true,
          items: [departmentSelectionConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let selectionText = '';
            if (
              values.department?.filterModeId ==
              DepartmentFilterModeOption.AllDepartmentsExceptSelectedDeparment
            ) {
              selectionText += allExceptText.replace(
                '{0}',
                values.department?.selectedDepartments?.length
              );
            } else {
              selectionText += values.department?.selectedDepartments?.length;
            }
            return values.department?.selectedDepartments
              ? {
                  visible: values.department?.selectedDepartments?.length > 0,
                  text: selectionText,
                  count: values.department?.selectedDepartments?.length,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: '::Agent',
          key: 'user',
          items: [userSelectionConfiguration],
          ignoreKeyOnValueCollection: true,
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let selectionText = '';
            if (
              values.user?.filterModeId == AgentListFilterModeOption.AllAgentsExceptSelectedAgent
            ) {
              selectionText += allExceptText.replace('{0}', values.user?.selectedUsers?.length);
            } else {
              selectionText += values.user?.selectedUsers?.length;
            }
            return values.user?.selectedUsers
              ? {
                  visible: values.user?.selectedUsers?.length > 0,
                  text: selectionText,
                  count: values.user?.selectedUsers?.length,
                }
              : {
                  visible: false,
                  text: '',
                  count: 0,
                };
          },
        },
        {
          title: 'CA::Call',
          key: 'call',
          ignoreKeyOnValueCollection: false,
          items: [
            callerNumberConfiguration,
            calledNumberConfiguration,
            relatedGroupIdConfiguration,
            callAnalysisStatusSelectionConfiguration,
            callListeningStatusSelectionConfiguration,
            callDirectionSelectionConfiguration,
            callReleasingPartySelectionConfiguration,
            minHoldDurationConfiguration,
            pbxAgentIdConfiguration,
          ],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let counter = 0;

            for (const prop in values.call) {
              if (values.call.hasOwnProperty(prop)) {
                const val = values.call[prop];

                if (typeof val === 'string' && val.trim().length > 0) {
                  counter++;
                } else if (typeof val === 'number' && val > 0) {
                  counter++;
                } else if (val && val.length > 0) {
                  counter++;
                }
              }
            }

            return counter === 0
              ? { visible: false, text: '', count: 0 }
              : { visible: true, text: counter.toString(), count: counter };
          },
        },
        {
          title: 'CA::Chat',
          key: 'chat',
          ignoreKeyOnValueCollection: false,
          items: [chatTitleConfiguration, chatParticipantConfiguration],
          getBadgeText(values: any): { visible: boolean; text: string; count: number } {
            let counter = 0;

            for (const prop in values.chat) {
              if (values.chat.hasOwnProperty(prop)) {
                const val = values.chat[prop];

                if (typeof val === 'string' && val.trim().length > 0) {
                  counter++;
                }
              }
            }

            return counter === 0
              ? { visible: false, text: '', count: 0 }
              : { visible: true, text: counter.toString(), count: counter };
          },
        },
      ],
    };

    this.canSeeEvaluationFilter = this.permissionService.getGrantedPolicy(
      'Conversation.CanSeeEvaluationFilter'
    );

    if (this.canSeeEvaluationFilter) {
      const evaluationFilter = {
        title: 'CA::Evaluation',
        key: 'evaluation',
        ignoreKeyOnValueCollection: false,
        items: [evaluationStatusSelectionConfiguration, evaluationMarkSelectionConfiguration],
        getBadgeText(values: any): { visible: boolean; text: string; count: number } {
          let counter = 0;

          for (const prop in values.evaluation) {
            if (values.evaluation.hasOwnProperty(prop)) {
              const val = values.evaluation[prop];

              if (typeof val === 'number' && val > 0) {
                counter++;
              }
            }
          }

          return counter === 0
            ? { visible: false, text: '', count: 0 }
            : { visible: true, text: counter.toString(), count: counter };
        },
      };
      this.filterPanelConfig.items.push(evaluationFilter);
    }

    const tagFilter = {
      title: '::Tag',
      key: 'tag',
      items: [tagSelectionConfiguration],
      ignoreKeyOnValueCollection: true,
      getBadgeText(values: any): { visible: boolean; text: string; count: number } {
        return values.tag && values.tag.length > 0
          ? {
              visible: true,
              text: values.tag.length,
              count: values.tag.length,
            }
          : {
              visible: false,
              text: '',
              count: 0,
            };
      },
    };
    this.filterPanelConfig.items.push(tagFilter);

    const otherFilters = {
      title: '::Others',
      key: 'others',
      items: [
        attachedDataSelectionConfiguration,
        durationConfiguration,
        screenRecordStatusSelectionConfiguration,
        ratingForNPSConfiguration,
      ],
      ignoreKeyOnValueCollection: false,
      getBadgeText(values: any): { visible: boolean; text: string; count: number } {
        let counter = 0;
        for (const prop in values.others) {
          if (values.others.hasOwnProperty(prop)) {
            if (prop == 'attachedData') {
              if (values.others.attachedData?.id > 0) {
                counter++;
              }
            } else if (prop == 'duration') {
              let isDefault =
                values.others.duration?.operator == defaultOperator &&
                values.others.duration?.editorValue == 0;
              if (!isDefault) {
                counter++;
              }
            } else if (prop == 'ratingForNPS') {
              let isDefault =
                values.others.ratingForNPS?.operator == defaultOperator &&
                values.others.ratingForNPS?.editorValue == 0;
              if (!isDefault) {
                counter++;
              }
            } else if (prop == 'screenRecordStatus') {
              if (values.others[prop] != '-1') {
                counter++;
              }
            } else {
              const val = values.others[prop];
              if (typeof val === 'string' && val.trim().length > 0) {
                counter++;
              } else if (typeof val === 'number' && val > 0) {
                counter++;
              }
            }
          }
        }

        return counter === 0
          ? { visible: false, text: '', count: 0 }
          : { visible: true, text: counter.toString(), count: counter };
      },
    };

    if (!this.featureService.isEnabled(FeatureConstants.ScreenRecorder)) {
      otherFilters.items = otherFilters.items.filter(
        x => x != screenRecordStatusSelectionConfiguration
      );
    }

    this.filterPanelConfig.items.push(otherFilters);
  }

  ngOnInit(): void {}

  getActiveListType(types: number[]): number {
    if (types.length === 0 || types.length > 1) {
      return ConversationListType.mixed;
    } else {
      return types[0];
    }
  }

  onFilterValueChanges(eventArgs: {
    config: FilterPanelConfigurationModel;
    values: any;
    buttonClicked: FilterButtonType;
  }) {
    const quickSearchTerm = eventArgs.values.quickSearchTerm;
    const quickSearchText = Number(quickSearchTerm.quickSearchText);
    if (eventArgs.buttonClicked !== FilterButtonType.NotButton) {
      const savePageAction = new SavePage(0);
      this.store.dispatch(savePageAction);
    }

    if (!isNaN(quickSearchText) && quickSearchText > 0) {
      this.conversationService.checkConversationById(quickSearchText).subscribe(data => {
        if (data) {
          this.router.navigate(['/conversation', 'v2', quickSearchText]);
        } else {
          // localizationService.instant bug var. qSearchValue ilk degerini aliyor sadece.
          let message = this.localizationService.instant('Conversation::ThereAreNoConversations');
          message = message.replace('{0}', quickSearchText.toString());
          this.toastr.info(message, '', {});
        }
      });
      return;
    } else {
      const actionActiveListChange = new ActiveListChange(
        this.getActiveListType(eventArgs.values.conversationListType)
      );
      this.store.dispatch(actionActiveListChange);

      const action = new FilterChange(eventArgs.values);
      this.store.dispatch(action);
    }
  }

  onFilterPanelCollapsed(eventArgs: { width: number }): void {
    this.collapsed.emit(eventArgs);
  }

  onFilterPanelExpanded(eventArgs): void {
    this.expanded.emit(eventArgs);
  }

  getDateSelectionDefault() {
    if (this.queryParamFilters?.date !== undefined) {
      return {
        shortcut: this.queryParamFilters.date.shortcut,
        start: new Date(this.queryParamFilters.date.start),
        end: new Date(this.queryParamFilters.date.end),
      };
    } else {
      return {
        shortcut: 0,
        start: null,
        end: null,
      };
    }
  }

  getQuerySelectionDefault() {
    if (this.queryParamFilters?.query !== undefined) {
      let query = new CategoryDto();
      query.name = this.queryParamFilters?.query.name;
      query.queryId = Number(this.queryParamFilters?.query.queryId);
      query.categoryType = CategoryType.Query;
      return [query];
    } else {
      return null;
    }
  }
}
